// Footer Primary Styles
#footerWrapper {
	font-size: 0;
	overflow: hidden;
	.contacts-wrapper {
		position: relative;
		padding-top: 6px;
		&:before {
			content: "";
			height: 1px;
			position: absolute;
			left: -50vw; top: 0;
			right: -50vw;
			background-color: $theme-primary;
		}
	}
	h3 {
		text-transform: uppercase;
		font-size: 14px; line-height: 16px;
		display: inline-block; vertical-align: top;
		width: 82px;
	}
	.address {
		p {
			width: calc(100% - 82px);
			display: inline-block; vertical-align: top;
			font-size: 14px; line-height: 16px;
			margin-bottom: 0;
			&.in-desktop { display: none; }
		}
	}
	p.email { display: none; }
	p.phone {
		position: relative;
		padding: 6px 0;
		margin-bottom: 0;
		font-size: 14px; line-height: 16px;
		margin-left: 82px;
		&:before {
			content: "";
			height: 1px;
			position: absolute;
			left: -50vw; top: 0;
			right: -50vw;
			background-color: $theme-primary;
		}
		&.in-desktop { display: none; }
	}
	.social-nav {
		position: relative;
		padding: 6px 0;
		&:before {
			content: "";
			height: 1px;
			position: absolute;
			left: -50vw; top: 0;
			right: -50vw;
			background-color: $theme-primary;
		}
		ul {
			display: inline-block; vertical-align: top;
			li {
				display: inline-block; vertical-align: top;
				margin-right: 10px;
				font-size: 14px; line-height: 16px;
			}
		}
	}
	p.copyright {
		padding: 6px 0;
		font-size: 12px; line-height: 16px;
		font-weight: 300;
		margin-bottom: 0;
		position: relative;
		&:before {
			content: "";
			height: 1px;
			position: absolute;
			left: -50vw; top: 0;
			right: -50vw;
			background-color: $theme-primary;
		}
	}

	@media (min-width: $screen-md-min) {
		padding-bottom: 40px;
		.contacts-wrapper {
			padding: 10px 0;
		}
		.address {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			margin-right: 20px;
			p.in-mobile { display: none; }
			p.in-desktop { display: inline-block; }
		}
		p.email {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px; line-height: 16px;
			margin-bottom: 0;
			margin-right: 20px;
		}
		p.phone {
			display: inline-block;
			vertical-align: middle;
			padding: 0;
			margin-bottom: 0;
			margin-left: 0;
			&:before { display: none; }
			&.in-mobile { display: none; }
			&.in-desktop { display: inline-block; }
		}
		.social-nav {
			padding: 10px 0;
		}
		p.copyright {
			padding: 10px 0;
		}
	}

	p.oracle {
		background-color: $theme-primary;
		color: $theme-light;
		margin-bottom: 0;
		padding: 10px 0;
		font-size: 20px;
		line-height: 20px;
		text-transform: uppercase;
		visibility: hidden;
		div {
			color: $theme-light !important;
			background-color: $theme-primary !important;
		}
		@media (min-width: $screen-md-min) {
			position: fixed;
			bottom: 0; left: 0;
			width: 100%;
		}
	}
}
