// Header Primary Styles
#headerWrapper {
	padding-top: 16px;
	position: relative;
	z-index: 2;
	h1 {
		margin: 0 auto;
		width: 100%;
		max-width: 308px;
		height: 71px;
		background: url($img-svg-url+'logo-mobile.svg') center center no-repeat transparent;
		background-size: contain;
		a,a:visited {
			display: block;
			width: 100%;
			height: 100%;
			@include hide-text();
		}
	}
	.contact-button { display: none; }

	@media (min-width: $screen-md-min) {
		.header { position: relative; }
		h1 {
			max-width: 760px;
			height: 118px;
			margin-left: 0;
			background-image: url($img-svg-url+'logo.svg');
		}
		.contact-button {
			display: block;
			position: absolute;
			top: 0; right: 0;
			display: block;
			background-color: $theme-primary;
			border-radius: 5px;
			padding: 9px;
			padding-bottom: 7px;
			text-transform: uppercase;
			font-size: 23px;
			line-height: 23px;
			color: $theme-light;
			outline: 1px solid $theme-bg-color;
			&:after {
				content: "";
				display: inline-block;
				width: 21px; height: 21px;
				margin-top: 1px;
				background: url($img-svg-url+'arrow.svg') center center no-repeat transparent;
				background-size: contain;
				margin-left: 3px;
			}
			&:hover {
				background-color: $theme-bg-color;
				text-decoration: none;
				outline: 1px solid $theme-primary;
			}
		}
	}
}
