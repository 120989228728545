/*!
 * App CSS
 * @description App CSS
 * @author Ricardo Lopes
 */
html, body {
	min-height: 100%;
	//height: 100%;
	background-color: $theme-bg-color;
	font-family: $font-primary;
	color: $theme-text-color;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-text-size-adjust:none;
	font-weight: 400;
}
.clear { &:after, &:before { content: ""; display: table; } &:after { clear: both; } *zoom: 1; }
.clearfix:after { clear:both; content:""; display:block; height:0pt; visibility:hidden; }
img,video {
	border: none;
	&.responsive { max-width: 100%; height: auto; }
	&.responsive-full { max-width: none; width: 100%; height: auto;	 }
}
::selection {background: $theme-primary; color: $theme-light; /* Safari */}
::-moz-selection {background: $theme-primary; color: $theme-light; /* Firefox */}
img::selection { color: white; background: transparent; }
img::-moz-selection { color: white; background: transparent; }
input,textarea,select {outline: none;}
h1,h2,h3,h4,h5,h6 { font-family: $font-secondary; color: $theme-primary; font-weight: 400; margin: 0; }
p { font-family: $font-primary; font-size: 16px; line-height: 24px; margin-bottom: 24px; font-weight: 400; color: $theme-text-color; }
b,strong { font-weight: 700; }
a {
	color: $theme-primary; text-decoration: none;
	@media (min-width: $screen-lg-min) {
		@include transition();
		&:hover { text-decoration: underline; @include transition(); }
	}
}
.fleft { float: left; }
.fright { float: right; }
.no-padding { padding: 0; }
.hide-text { text-indent: 100%; white-space: nowrap; overflow: hidden; }
table { td { vertical-align: top; } width: 100%; }
ul { list-style-type: none; margin: 0; padding: 0; }

.hidden {display: none;}
.hidden-xs {display:none;}
@media (min-width: $screen-sm-min) { .hidden-xs {display:block;} }
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { .hidden-sm {display:none} }
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) { .hidden-md {display:none} }
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { .hidden-lg {display:none;} }
@media (min-width: $screen-xl-min) { .hidden-xl {display:none;} }
.visible-xs { display: none; }
.visible-sm { display: none; }
@media (max-width: $screen-xs-max) { .visible-xs {display:block} }
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { .visible-sm {display:block} }
