// Custom Mixins
// Description: Custom Mixins for use throughout the application
@mixin background-cover() {
	-moz-background-size:cover; 
	-webkit-background-size:cover; 
	background-size:cover;
}

@mixin border-radius($topleft: 5px, $topright: 5px, $bottomright: 5px, $bottomleft: 5px) { 
	-webkit-border-radius: $topleft $topright $bottomright $bottomleft;
	-moz-border-radius: $topleft $topright $bottomright $bottomleft;
	border-radius: $topleft $topright $bottomright $bottomleft;
}

@mixin border-radius-whole($radius: 5px) {
    -webkit-background-clip: padding-box;
    -webkit-border-radius: $radius;
    -moz-background-clip: padding-box;
    -moz-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin box-sizing() { 
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; 
}

@mixin box-shadow($x:0, $y:5px, $blur:3px, $color: $default) {
  box-shadow: $x $y $blur $color;
  -o-box-shadow: $x $y $blur $color; 
  -webkit-box-shadow: $x $y $blur $color; 
  -moz-box-shadow: $x $y $blur $color;
}

@mixin centered($width: 1020px) { 
	margin: 0 auto 0 auto;	
	width: $width;
}

@mixin circle($size:20px) {
	@include border-radius-whole(50%);
	display: inline-block;
	height: $size;
	width: $size;
	text-align: center;
}

@mixin debug($color: $msg-info) { 
	background: $color;
}

@mixin divider($color: $default) { 
	border-top: 1px solid $color;
	clear: both;
}

@mixin gradient-horizontal($from, $to) {
	background-color: $from;
	background-repeat: repeat-x;
	background: -webkit-gradient(linear, 0% 0%, 100% 0%, from($from), to($to));
	background: -webkit-linear-gradient(left top, $from, to);
	background: -moz-linear-gradient(left top, $from, $to);
	background: -ms-linear-gradient(left top, $from, $to);
	background: -o-linear-gradient(left top, $from, $to);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to, GradientType=1);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to, GradientType=1)";	
}

@mixin gradient-vertical($from,$to) {
	background-color: $from;
	background-repeat: repeat-x;
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
	background: -webkit-linear-gradient(top, $from, to);
	background: -moz-linear-gradient(top, $from, $to);
	background: -ms-linear-gradient(top, $from, $to);
	background: -o-linear-gradient(top, $from, $to);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to)";	
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to);
}

@mixin inset-shadow($x:0, $y:2px, $blur:5px, $spread:-2px, $color:$default) { 
	-moz-box-shadow:inset $x $y $blur $spread $color;
	-webkit-box-shadow:inset $x $y $blur $spread $color;
	box-shadow:inset $x $y $blur $spread $color;
}

@mixin text-shadow($h:0.1em, $v:0.1em, $blur:0, $color:$default) {
	text-shadow: $h $v $blur $color;
}

@mixin transform($deg:-90deg) { 
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	-o-transform: rotate($deg);
}
@mixin transformSkewX($deg:-90deg) { 
	-webkit-transform: skewX($deg);
	-moz-transform: skewX($deg);
	-ms-transform: skewX($deg);
	-o-transform: skewX($deg);
}
@mixin vertical-centered() { 
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}

@mixin vertical-centered2() { 
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}
@mixin vertical-centered3() { 
	position: absolute;
	top: 50%;
	left: 0; width: 100%;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}
@mixin translateY($posY:-50%) { 
	-webkit-transform: translateY($posY);
	-moz-transform: translateY($posY);
	-ms-transform: translateY($posY);
	-o-transform: translateY($posY);
	transform: translateY($posY);
}
@mixin translateXY($posX:-50%,$posY:-50%) { 
	-webkit-transform: translate($posX,$posY);
	-moz-transform: translate($posX,$posY);
	-ms-transform: translate($posX,$posY);
	-o-transform: translate($posX,$posY);
	transform: translate($posX,$posY);
}

@mixin transition($seconds:0.4s) { 
	-moz-transition: $seconds ease;
	-ms-transition: $seconds ease;
	-o-transition:$seconds ease;
	transition: $seconds ease;
	-webkit-transition: $seconds ease;	
}

@mixin transparent($op: 0.5, $ieop: 50) {
	filter: alpha(opacity=$ieop);
	-moz-opacity:$op;
	-khtml-opacity: $op;
	opacity: $op;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieop)";	
}

@mixin hide-text() {
	line-height: 500px;
	overflow: hidden;
}

@mixin gradient-text($direction: left, $color1: $theme-gradient-color-1 10%, $color2: $theme-gradient-color-2 50%, $color3: $theme-gradient-color-3 90%) {
	background: -webkit-linear-gradient(left, $color1, $color2,$color3); /* Safari 5.1 to 6.0 */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin scale($p:1.1) { 
	-webkit-transform: scale($p);
	-moz-transform: scale($p);
	-ms-transform: scale($p);
	-o-transform: scale($p);
	transform: scale($p);
}

@mixin placeholder() {
	&::-webkit-input-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&:-moz-placeholder { @content; }
}