// --------------------------------------------------
// IMAGE PATHS
// --------------------------------------------------
// Example of how to use -- background: url("#{$img-url}/background-image.png") repeat top left;
$img-url: "../img/";
$img-cnt-url: "../img/cnt/";
$img-svg-url: "../img/svg/";

// --------------------------------------------------
// COLORS
// --------------------------------------------------
$white: #fff;
$black: #000;

//custom
$theme-bg-color: #E83737;

$theme-primary: #151515;
$theme-secondary: #151515;
$theme-text-color: #151515;
$theme-text-color-light: #FFFFFF;

$theme-light: #FFFFFF;
$theme-dark: #000000;

// --------------------------------------------------
// FONTS
// --------------------------------------------------
$font-filepath: '../webfonts/';
$font-primary: 'prosa', Helvetica, Arial, sans-serif;
$font-secondary: 'prosa', Helvetica, Arial, sans-serif;

// --------------------------------------------------
// Media queries breakpoints
// --------------------------------------------------
$screen-xs:                  320px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

$screen-lg:                  1024px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

$screen-xl:                  1260px !default;
$screen-xl-min:              $screen-xl !default;
$screen-xl-large-desktop:    $screen-xl-min !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              ($screen-xl-min - 1) !default;

// Grid Bootstrap default values
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1260px ) !default;
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px ) !default;

// Grid Custom default values
$max-container-big-width: 1440px;
$container-width: 1400px;
