.hp-wrapper {
	margin-top: -20px;
	margin-bottom: 10px;
	position: relative;
	@media (min-width: $screen-md-min) {
		margin-top: -60px;
	}

	.contact-button {
		display: inline-block;
		background-color: $theme-primary;
		border-radius: 5px;
		padding: 8px;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 18px;
		color: $theme-light;
		outline: 1px solid $theme-bg-color;
		&:after {
			content: "";
			display: inline-block;
			width: 18px; height: 18px;
			margin-top: 1px;
			background: url($img-svg-url+'arrow.svg') center center no-repeat transparent;
			background-size: contain;
			margin-left: 18px;
		}
	}
	@media (min-width: $screen-md-min) {
		.contact-button { display: none; }
	}
}

.text-artifacts {
	display: none;
	@media (min-width: $screen-md-min) {
		display: block;
		p { display: none; }
		margin: 0 auto;
		margin-top: 20px;
		width: 100%;
		max-width: 1400px;
		height: 46px;
		background: url($img-svg-url+'text-artifacts.svg') center center no-repeat transparent;
		background-size: contain;
	}
}

.images-slider {
	&.in-desktop { display: none; }

	width: 100%;
	height: 430px;
	position: relative;
	margin-bottom: 8px;

	.image-wrapper {
		display: none;
		&.active { display: block; }
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0; left: 0;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	@media (min-width: $screen-md-min) {
		&.in-mobile { display: none; }
		&.in-desktop { display: block; }

		margin: 0 auto;
		width: 100%;
		height: 620px;
	}
}
