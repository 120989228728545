body { &.in-popup,&.is-in-popup { overflow: hidden; } }

#mainWrapper,#main {
	@media (min-width: $screen-md-min) {
	}
}
.container {
	max-width: $container-width;
	margin: 0 auto;
	padding: 0 16px;
	@media (min-width: $screen-md-min) {
		padding: 0 20px;
	}
}
#contentPageWrapper {}
a.button,a.button:visited {
	@media (min-width: $screen-lg-min) {
		&:hover {}
	}
}
a.full-link,a.full-link:visited { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

input:focus::-webkit-input-placeholder { color:transparent !important; }
input:focus:-moz-placeholder { color:transparent !important; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent !important; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent !important; } /* IE 10+ */

textarea:focus::-webkit-input-placeholder { color:transparent !important; }
textarea:focus:-moz-placeholder { color:transparent !important; } /* FF 4-18 */
textarea:focus::-moz-placeholder { color:transparent !important; } /* FF 19+ */
textarea:focus:-ms-input-placeholder { color:transparent !important; } /* IE 10+ */

textarea:focus, input:focus{ outline: none; }
select { outline: none; }
.swiper-custom-nav span { outline: none; }
.swiper-custom-prev { outline: none; }
.swiper-custom-next { outline: none; }

.section-content {}
